<template>
  <div>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter un service
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier un service
                </h3>
              </div>
              <!--begin::Form-->
              <form
                class="form"
                @submit.prevent="save"
                enctype="multipart/form-data"
              >
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label>Titre</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.titre"
                        :class="{ 'is-invalid': errors.titre }"
                      />
                      <span class="form-text text-danger" v-if="errors.titre">{{
                        errors.titre[0]
                      }}</span>
                    </div>
                    <div class="col-lg-2">
                      <label>Service diaspora ?</label>
                      <div class="radio-inline">
                        <label class="radio radio-solid">
                          <input
                            type="radio"
                            v-model="form.diaspora"
                            value="1"
                          />
                          <span></span>OUI</label
                        >
                        <label class="radio radio-solid">
                          <input
                            type="radio"
                            v-model="form.diaspora"
                            value="0"
                          />
                          <span></span>NON</label
                        >
                        <span
                          class="form-text text-danger"
                          v-if="errors.cour"
                          >{{ errors.cour[0] }}</span
                        >
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <label class="btn btn-primary"
                        >Ajouter image
                        <input
                          id="customFile"
                          ref="file"
                          @change="handleFileObject"
                          type="file"
                          accept="image/*"
                          class="form-control-file"
                          :class="{ 'is-invalid': errors.cover_image }"
                        />
                      </label>
                      <span
                        class="form-text text-danger"
                        v-if="errors.cover_image"
                        >{{ errors.cover_image[0] }}</span
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <label>Descriptif</label>
                      <textarea
                        v-model="form.subtitle"
                        rows="3"
                        class="form-control"
                        :class="{ 'is-invalid': errors.subtitle }"
                      ></textarea>
                      <span
                        class="form-text text-danger"
                        v-if="errors.subtitle"
                        >{{ errors.subtitle[0] }}</span
                      >
                    </div>
                    <div class="col-lg-12 mt-3">
                      <label>Description</label>
                      <ckeditor
                        :editor="editor"
                        v-model="form.description"
                        :config="editorConfig"
                      ></ckeditor>
                      <span
                        class="form-text text-danger"
                        v-if="errors.description"
                        >{{ errors.description[0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button type="submit" class="btn btn-primary mr-2">
                        Enregistrer
                      </button>
                      <button type="reset" class="btn btn-secondary">
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Form",
  data() {
    return {
      initialize: "service/create",
      store: "service",
      method: "post",
      form: {},
      success: false,
      has_error: false,
      error: "",
      errors: {},
      cover_image: null,
      cover_image_name: null,
      editor: ClassicEditor,
      editorConfig: {}
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "service/" + this.$route.params.id + "/edit";
      this.store = "service/" + this.$route.params.id;
      this.method = "post";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
      });
    },
    save() {
      let formData = new FormData();
      let vm = this;
      formData.append("cover_image", this.cover_image);
      Object.entries(this.form).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.$route.meta.mode === "update") {
        formData.append("_method", "PUT");
      }

      let config = {
        headers: {
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random()
              .toString()
              .substr(2)
        }
      };

      ApiService[this.method](this.store, formData, config)
        .then(function(response) {
          if (response.data.success) {
            Swal.fire({
              title: "Reussie",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
            vm.$router.push(vm.$route.meta.back);
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
        });
    },
    handleFileObject() {
      this.cover_image = this.$refs.file.files[0];
      this.cover_image_name = this.cover_image.name;
    }
  }
};
</script>

<style scoped></style>
